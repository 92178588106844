<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-row>
      <b-col
        cols="12"
        md="6"
        sm="12"
        v-if="e_contract != null && paymentSession.e_contract != null"
      >
        <b-card-code title="E-contract Data" :before-change="validationForm">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <!-- Seller name -->
                <b-col md="12">
                  <b-form-group label="Seller Name" label-for="first-name">
                    <validation-provider
                      #default="{ errors }"
                      name="Seller Name"
                      rules="required"
                    >
                      <b-form-input
                        id="first-name"
                        v-model="e_contract.seller_name"
                        :disabled="
                          !authPermissions.includes('update-eContract-data') ||
                          paymentSession.e_contract.e_contract_image != null
                        "
                        placeholder="John"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Seller Id Number -->
                <b-col md="12">
                  <b-form-group label="Seller Id Number" label-for="seller-id">
                    <validation-provider
                      #default="{ errors }"
                      name="Seller Id Number"
                      rules="required"
                    >
                      <b-form-input
                        :disabled="
                          !authPermissions.includes('update-eContract-data') ||
                          paymentSession.e_contract.e_contract_image != null
                        "
                        id="seller-id"
                        v-model="e_contract.seller_id_number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-row>
                    <!-- Id front picture -->
                    <b-col md="12">
                      <b-form-group
                        label="Seller Id front picture"
                        label-for="Seller Id front picture"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Seller Id front picture"
                        >
                          <b-form-file
                            v-if="
                              authPermissions.includes(
                                'update-eContract-data'
                              ) ||
                              paymentSession.e_contract.e_contract_image != null
                            "
                            v-model="seller_id_front_image_file"
                            @change="ChangeSellerIDFrontImage($event)"
                            id="ChangeSellerIDFrontImage"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Id front picture Image Frame -->
                    <b-col cols="12" class="text-center">
                      <b-img
                        width="200"
                        height="auto"
                        :src="paymentSession.e_contract.seller_id_front_image"
                      />
                      <!-- <iframe width="200" height="200" :src="item.value"> </iframe> -->
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" md="6">
                  <b-row>
                    <!-- Id back picture -->
                    <b-col md="12">
                      <b-form-group
                        label="Seller Id back picture"
                        label-for="Seller Id back picture"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Seller Id back picture"
                        >
                          <b-form-file
                            v-if="
                              authPermissions.includes(
                                'update-eContract-data'
                              ) ||
                              paymentSession.e_contract.e_contract_image != null
                            "
                            v-model="seller_id_back_image_file"
                            @change="ChangeSellerIDBackImage($event)"
                            id="ChangeSellerIDBackImage"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Id back picture Image Frame -->
                    <b-col cols="12" class="text-center">
                      <b-img
                        width="200"
                        height="auto"
                        :src="paymentSession.e_contract.seller_id_back_image"
                      />
                      <!-- <iframe width="200" height="200" :src="item.value"> </iframe> -->
                    </b-col>
                  </b-row>
                </b-col>

              </b-row>
              <hr />
              <b-row>
                <!-- Customer name -->
                <b-col md="12">
                  <b-form-group label="Customer Name" label-for="first-name">
                    <validation-provider
                      #default="{ errors }"
                      name="Customer Name"
                      rules="required"
                    >
                      <b-form-input
                        :disabled="
                          !authPermissions.includes('update-eContract-data') ||
                          paymentSession.e_contract.e_contract_image != null
                        "
                        id="first-name"
                        v-model="e_contract.customer_name"
                        placeholder="John"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Customer Id Number -->
                <b-col md="12">
                  <b-form-group
                    label="Customer Id Number"
                    label-for="customer-id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Customer Id Number"
                      rules="required"
                    >
                      <b-form-input
                        :disabled="
                          !authPermissions.includes('update-eContract-data') ||
                          paymentSession.e_contract.e_contract_image != null
                        "
                        id="customer-id"
                        v-model="e_contract.customer_id_number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-row>
                    <!-- Id front picture -->
                    <b-col md="12">
                      <b-form-group
                        label="Id front picture"
                        label-for="Id front picture"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Id front picture"
                        >
                          <b-form-file
                            v-if="
                              authPermissions.includes(
                                'update-eContract-data'
                              ) ||
                              paymentSession.e_contract.e_contract_image != null
                            "
                            v-model="id_front_image_file"
                            @change="ChangeIDFrontImage($event)"
                            id="ProfilePictureBase642"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Id front picture Image Frame -->
                    <b-col cols="12" class="text-center">
                      <b-img
                        width="200"
                        height="auto"
                        :src="paymentSession.e_contract.id_front_image"
                      />
                      <!-- <iframe width="200" height="200" :src="item.value"> </iframe> -->
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" md="6">
                  <b-row>
                    <!-- Id back picture -->
                    <b-col md="12">
                      <b-form-group
                        label="Id back picture"
                        label-for="Id back picture"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Id back picture"
                        >
                          <b-form-file
                            v-if="
                              authPermissions.includes(
                                'update-eContract-data'
                              ) ||
                              paymentSession.e_contract.e_contract_image != null
                            "
                            v-model="id_back_image_file"
                            @change="ChangeIDBackImage($event)"
                            id="ProfilePictureBase643"
                            accept="image/*"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Id back picture Image Frame -->
                    <b-col cols="12" class="text-center">
                      <b-img
                        width="200"
                        height="auto"
                        :src="paymentSession.e_contract.id_back_image"
                      />
                      <!-- <iframe width="200" height="200" :src="item.value"> </iframe> -->
                    </b-col>
                  </b-row>
                </b-col>

                <!-- submit button -->
                <b-col
                  class="py-1 text-center"
                  v-if="paymentSession.e_contract.e_contract_image == null"
                >
                  <b-button
                    size="lg"
                    class="mx-1 my-1"
                    variant="outline-primary"
                    type="submit"
                    @click.prevent="validationForm"
                    v-if="authPermissions.includes('update-eContract-data')"
                  >
                    Update
                  </b-button>

                  <b-button
                    size="lg"
                    variant="outline-success"
                    @click="generateEContract"
                    v-if="authPermissions.includes('generate-eContract')"
                  >
                    Generate E-Contract
                  </b-button>
                </b-col>
                <!-- submit button -->
                <!-- <b-col
                  class="py-1 text-center"
                  v-if="
                    authPermissions.includes('generate-eContract')
                  "
                >
                  <b-button
                    size="lg"
                    variant="outline-success"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Generate E-Contract
                  </b-button>
                </b-col> -->
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-code>
      </b-col>

      <!-- E-COntract Preview Section -->
      <b-col cols="12" md="6" sm="12" v-if="paymentSession.e_contract">
        <b-card-code class="table-responsive">
          <svg
            width="595"
            height="993"
            viewBox="0 0 595 993"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="595" height="993" fill="#1E1E1E" />
            <g clip-path="url(#clip0_0_1)">
              <rect width="595" height="993" fill="white" />
              <rect
                x="-0.822418"
                y="850"
                width="596.822"
                height="143.1"
                fill="#FF5100"
              />
              <rect
                x="38"
                y="865"
                width="128.007"
                height="128.389"
                fill="#08308B"
              />
              <text
                fill="#FF5100"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="16.0486"
                font-weight="300"
                letter-spacing="0em"
              >
                <tspan x="54" y="942.065">MobileMasr</tspan>
              </text>
              <text
                fill="#FF5100"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Montserrat"
                font-size="12.74"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="54" y="923.332">eContract&#10;</tspan>
              </text>
              <path
                d="M521.33 885.364H517.666V889.027H521.33V885.364Z"
                fill="white"
              />
              <path
                d="M517.665 889.027H514.001V892.691H517.665V889.027Z"
                fill="white"
              />
              <path
                d="M510.338 892.691H514V889.027H510.338V885.364H506.675V889.027V889.027V892.691H510.336H510.338Z"
                fill="white"
              />
              <path
                d="M514.001 896.354V896.354V900.018H517.665V896.354V896.354V892.691H514.001V896.354Z"
                fill="white"
              />
              <path
                d="M510.336 903.681H514V900.018V900.017V896.354H510.338V892.691H506.675V896.354V896.354V900.018H510.336V903.681Z"
                fill="white"
              />
              <path
                d="M521.33 900.017H517.666V903.68H521.33V900.017Z"
                fill="white"
              />
              <path
                d="M521.33 907.343H517.666V911.007H521.33V907.343Z"
                fill="white"
              />
              <path
                d="M510.338 903.683H506.675V907.346H510.338V903.683Z"
                fill="white"
              />
              <path
                d="M517.665 903.683H514.001V907.346H517.665V903.683Z"
                fill="white"
              />
              <path
                d="M510.338 911.009H506.675V914.672H510.338V911.009Z"
                fill="white"
              />
              <path
                d="M484.69 914.675H481.027V918.339H484.69V914.675Z"
                fill="white"
              />
              <path
                d="M503.009 918.336V918.339V921.999H506.673V918.339V918.336V914.675H503.009V918.336Z"
                fill="white"
              />
              <path
                d="M517.299 918.339V914.675H513.636V918.336H510.338V914.675H506.675V918.339H510.336V921.999H514V918.339H517.299Z"
                fill="white"
              />
              <path
                d="M524.992 918.336V918.339V921.999H528.656V918.339V918.336V914.675H524.992V918.336Z"
                fill="white"
              />
              <path
                d="M539.647 914.675H535.984V918.336V918.339V921.999H539.645H539.647H543.308V918.336H539.647V914.675Z"
                fill="white"
              />
              <path
                d="M495.682 921.999V918.336H492.018V914.675H488.355H488.355H484.692V918.339H488.355V921.999H492.018H492.018H495.682Z"
                fill="white"
              />
              <path
                d="M499.344 918.336H495.683V922H499.344H499.347H503.008V918.336H499.347H499.344Z"
                fill="white"
              />
              <path
                d="M506.673 922.001H503.009V925.665H506.673V922.001Z"
                fill="white"
              />
              <path
                d="M535.982 918.336H532.319V922H535.982V918.336Z"
                fill="white"
              />
              <path
                d="M546.973 918.339V918.336V914.675H543.31V918.336V918.339V921.999H546.973V918.339Z"
                fill="white"
              />
              <path
                d="M550.639 918.336H546.975V922H550.639V918.336Z"
                fill="white"
              />
              <path
                d="M484.69 922.001H481.028H481.027H477.364V925.665H481.027H481.028H484.69V922.001Z"
                fill="white"
              />
              <path
                d="M492.018 922.001H488.355V925.665H492.018V922.001Z"
                fill="white"
              />
              <path
                d="M503.008 922.001H499.344V925.665H503.008V922.001Z"
                fill="white"
              />
              <path
                d="M514 922.001H510.336V925.665H514V922.001Z"
                fill="white"
              />
              <path
                d="M528.656 922.001H524.992V925.665H528.656V922.001Z"
                fill="white"
              />
              <path
                d="M521.327 922.001H517.666V925.665H521.327H521.33H524.99V922.001H521.33H521.327Z"
                fill="white"
              />
              <path
                d="M524.99 918.336H521.327V922H524.99V918.336Z"
                fill="white"
              />
              <path
                d="M543.308 922.001H539.645V925.665H543.308V922.001Z"
                fill="white"
              />
              <path
                d="M484.69 925.664H481.027V929.328H484.69V925.664Z"
                fill="white"
              />
              <path
                d="M492.018 925.664H488.355V929.328H492.018V925.664Z"
                fill="white"
              />
              <path
                d="M499.347 925.664H495.683V929.328H499.347V925.664Z"
                fill="white"
              />
              <path
                d="M524.99 925.664H521.327V929.328H524.99V925.664Z"
                fill="white"
              />
              <path
                d="M528.656 925.664H524.992V929.328H528.656V925.664Z"
                fill="white"
              />
              <path
                d="M532.322 925.664H528.658V929.325V929.328V932.988H532.318H532.322H535.982V929.325H532.322V925.664Z"
                fill="white"
              />
              <path
                d="M539.645 925.664H535.984V929.325V929.328V932.988H539.645H539.647H543.308V929.328V929.325V925.664H539.647H539.645Z"
                fill="white"
              />
              <path
                d="M514 929.328H510.336V932.991H514V929.328Z"
                fill="white"
              />
              <path
                d="M546.974 929.328H543.31V932.991H546.974V929.328Z"
                fill="white"
              />
              <path
                d="M510.336 932.989H506.675V936.653H510.336H510.338H514V932.989H510.338H510.336Z"
                fill="white"
              />
              <path
                d="M528.656 932.989H524.992V936.653H528.656V932.989Z"
                fill="white"
              />
              <path
                d="M532.322 932.989H528.658V936.653H532.322V932.989Z"
                fill="white"
              />
              <path
                d="M543.308 932.989H539.645V936.653H543.308V932.989Z"
                fill="white"
              />
              <path
                d="M550.639 932.989H546.975V936.653H550.639V932.989Z"
                fill="white"
              />
              <path
                d="M517.665 936.659H514.001V940.322H517.665V936.659Z"
                fill="white"
              />
              <path
                d="M521.33 932.989H517.666V936.653H521.33V932.989Z"
                fill="white"
              />
              <path
                d="M532.318 936.659H528.658V940.322H532.318V943.983H535.982V940.322V940.32V936.659H532.322H532.318Z"
                fill="white"
              />
              <path
                d="M539.645 936.659H535.984V940.32V940.322V943.983H539.647V940.322H543.308V936.659H539.647H539.645Z"
                fill="white"
              />
              <path
                d="M546.974 936.659H543.31V940.322H546.974V936.659Z"
                fill="white"
              />
              <path
                d="M550.639 940.316H546.975V943.979H550.639V940.316Z"
                fill="white"
              />
              <path
                d="M510.336 936.659H506.675V940.32V940.322V943.983H510.338V940.322H514V936.659H510.338H510.336Z"
                fill="white"
              />
              <path
                d="M528.656 940.316H524.992V943.979H528.656V940.316Z"
                fill="white"
              />
              <path
                d="M510.336 947.647H506.675V951.31H510.338V947.65H514V943.986H510.336V947.647Z"
                fill="white"
              />
              <path
                d="M514.001 947.647V947.65V951.31H517.665V947.65V947.647V943.986H514.001V947.647Z"
                fill="white"
              />
              <path
                d="M532.318 947.647H528.658V951.31H532.318H532.322H535.982V947.65V947.647V943.986H532.318V947.647Z"
                fill="white"
              />
              <path
                d="M521.33 947.642H517.666V951.305H521.33V947.642Z"
                fill="white"
              />
              <path
                d="M539.647 947.642H535.984V951.305H539.647V947.642Z"
                fill="white"
              />
              <path
                d="M546.974 947.642H543.31V951.305H546.974V947.642Z"
                fill="white"
              />
              <path
                d="M514 951.312H510.336V954.976H514V951.312Z"
                fill="white"
              />
              <path
                d="M517.665 951.312H514.001V954.976H517.665V951.312Z"
                fill="white"
              />
              <path
                d="M524.99 951.312H521.327V954.976H524.99V951.312Z"
                fill="white"
              />
              <path
                d="M528.656 951.312H524.992V954.976H528.656V951.312Z"
                fill="white"
              />
              <path
                d="M532.322 951.312H528.658V954.976H532.322V951.312Z"
                fill="white"
              />
              <path
                d="M539.645 951.312H535.984V954.976H539.645H539.647H543.308V951.312H539.647H539.645Z"
                fill="white"
              />
              <path
                d="M546.974 951.312H543.31V954.976H546.974V951.312Z"
                fill="white"
              />
              <path
                d="M550.639 951.312H546.975V954.976H550.639V951.312Z"
                fill="white"
              />
              <path
                d="M517.665 954.973H514.001V958.637H517.665V954.973Z"
                fill="white"
              />
              <path
                d="M524.99 954.973H521.327V958.637H524.99V954.973Z"
                fill="white"
              />
              <path
                d="M532.318 954.973H528.658V958.637H532.318H532.322H535.982V954.973H532.322H532.318Z"
                fill="white"
              />
              <path
                d="M539.647 954.973H535.984V958.637H539.647V954.973Z"
                fill="white"
              />
              <path
                d="M546.974 954.973H543.31V958.637H546.974V954.973Z"
                fill="white"
              />
              <path
                d="M550.639 954.973H546.975V958.637H550.639V954.973Z"
                fill="white"
              />
              <path
                d="M502.771 885.364H477.364V910.77H502.771V885.364ZM499.457 907.457H480.678V888.678H499.457V907.457Z"
                fill="white"
              />
              <path
                d="M477.364 958.636H502.771V933.23H477.364V958.636ZM480.678 936.543H499.457V955.322H480.678V936.543Z"
                fill="white"
              />
              <path
                d="M550.638 885.364H525.232V910.77H550.638V885.364H550.638ZM547.324 907.457H528.545V888.678H547.324V907.457H547.324Z"
                fill="white"
              />
              <path
                d="M541.619 894.387H534.255V901.752H541.619V894.387Z"
                fill="white"
              />
              <path
                d="M493.75 894.387H486.386V901.752H493.75V894.387Z"
                fill="white"
              />
              <path
                d="M493.75 942.252H486.386V949.616H493.75V942.252Z"
                fill="white"
              />
              <path
                d="M545.299 878V881.314H554.688V890.703H558.002V878H545.299Z"
                fill="white"
              />
              <path
                d="M473.314 881.314H482.703V878H470V890.703H473.314V881.314Z"
                fill="white"
              />
              <path
                d="M473.314 953.297H470V966H482.703V962.686H473.314V953.297Z"
                fill="white"
              />
              <path
                d="M554.688 962.686H545.299V966H558.002V953.297H554.688V962.686Z"
                fill="white"
              />
              <rect width="595" height="93" fill="#08308B" />
              <text
                fill="white"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="14"
                font-weight="608"
                letter-spacing="0px"
              >
                <tspan x="67" y="35.0184">
                  {{ paymentSession.e_contract.code }} :
                  &#x645;&#x628;&#x627;&#x64a;&#x639;&#x629;
                  &#x627;&#x644;&#x643;&#x62a;&#x631;&#x648;&#x646;&#x64a;&#x629;
                  &#x631;&#x642;&#x645;
                  
                </tspan>
              </text>
              <text
                fill="white"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="12"
                font-weight="608"
                letter-spacing="0px"
              >
                <tspan x="126" y="53.8315">
                  &#x627;&#x644;&#x62a;&#x627;&#x631;&#x64a;&#x62e;
                  {{ convertDate(paymentSession.ar_date) }}
                </tspan>
              </text>
              <text
                fill="white"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="12"
                font-weight="608"
                letter-spacing="0px"
              >
                <tspan x="168" y="71.8315">
                  {{ (paymentSession.time) }}
                </tspan>
              </text>
              <path
                d="M549.797 57.8121H548.243C545.072 57.8121 542.496 55.2438 542.496 52.0654V31.4557H549.789V57.8121H549.797Z"
                fill="white"
              />
              <path
                d="M539.509 52.9523L538.502 53.6831L537.757 52.4344L537.012 53.6831L536.005 52.9523L536.969 51.8527L535.543 51.5405L535.934 50.3557L537.267 50.9375L537.14 49.5682H538.353L538.225 50.9942L539.538 50.4266L539.907 51.5831L538.566 51.881L539.502 52.9452L539.509 52.9523ZM537.729 47.979C535.714 47.979 534.082 49.6108 534.082 51.6256C534.082 53.6405 535.714 55.2722 537.729 55.2722C539.743 55.2722 541.375 53.6405 541.375 51.6256C541.375 49.6108 539.743 47.979 537.729 47.979Z"
                fill="#FF5100"
              />
              <path
                d="M558.353 60.3448H556.799C553.628 60.3448 551.052 57.7766 551.052 54.5982V31.4557H552.606C555.777 31.4557 558.353 34.0239 558.353 37.2023V60.3448Z"
                fill="white"
              />
              <path
                d="M537.736 46.922C539.204 46.922 540.517 47.5959 541.382 48.653V33.2932C541.382 29.2635 538.119 26 534.089 26V48.5182C534.089 48.5608 534.096 48.5963 534.103 48.6389C534.969 47.5889 536.274 46.922 537.736 46.922Z"
                fill="white"
              />
              <path
                d="M425.024 39.5151L426.088 30.1361C426.294 28.1922 427.067 27.4472 428.671 27.4472C430.274 27.4472 430.976 28.1922 431.338 30.1361L432.693 36.954C432.75 37.2591 432.863 37.3584 432.991 37.3584C433.119 37.3584 433.239 37.2591 433.289 36.954L434.651 30.1361C435.02 28.1922 435.701 27.4472 437.312 27.4472C438.922 27.4472 439.696 28.1922 439.901 30.1361L440.965 39.5151C441.036 40.1182 440.717 40.4729 440.107 40.4729H439.156C438.596 40.4729 438.27 40.1891 438.206 39.6145L437.283 30.7604C437.262 30.6185 437.198 30.5547 437.063 30.5547C436.929 30.5547 436.872 30.6256 436.858 30.7533L435.247 38.7915C434.999 39.9976 434.297 40.48 432.984 40.48C431.672 40.48 430.969 39.9976 430.728 38.7915L429.118 30.7533C429.096 30.6256 429.039 30.5547 428.912 30.5547C428.784 30.5547 428.713 30.6256 428.692 30.7604L427.77 39.6145C427.713 40.1891 427.386 40.4729 426.819 40.4729H425.868C425.258 40.4729 424.939 40.1182 425.01 39.5151"
                fill="white"
              />
              <path
                d="M442.32 35.6203C442.32 32.4064 444.087 30.576 447.322 30.576C450.557 30.576 452.324 32.4064 452.324 35.6203C452.324 38.8341 450.557 40.6503 447.322 40.6503C444.087 40.6503 442.32 38.8199 442.32 35.6203ZM449.536 35.6203C449.536 33.7047 448.805 32.8888 447.322 32.8888C445.839 32.8888 445.109 33.6976 445.109 35.6203C445.109 37.5429 445.839 38.3375 447.322 38.3375C448.805 38.3375 449.536 37.5145 449.536 35.6203Z"
                fill="white"
              />
              <path
                d="M463.817 35.6131C463.817 39.3732 461.76 40.6573 458.291 40.6573C455.403 40.6573 454.169 39.7705 454.169 37.5499V27.5465C454.169 26.9647 454.495 26.6384 455.077 26.6384H456.006C456.588 26.6384 456.914 26.9647 456.914 27.5465V30.6397C457.389 30.5972 457.851 30.583 458.291 30.583C461.76 30.583 463.817 31.8458 463.817 35.6131ZM461.072 35.6131C461.072 33.5343 460.298 32.8816 458.461 32.8816C458.014 32.8816 457.503 32.9242 456.921 33.0022V37.1313C456.921 37.933 457.077 38.3374 458.482 38.3374C460.298 38.3374 461.072 37.6563 461.072 35.606"
                fill="white"
              />
              <path
                d="M465.775 27.9793C465.775 27.0286 466.286 26.4469 467.308 26.4469C468.329 26.4469 468.84 27.0286 468.84 27.9793C468.84 28.93 468.336 29.4904 467.308 29.4904C466.279 29.4904 465.775 28.93 465.775 27.9793Z"
                fill="white"
              />
              <path
                d="M465.91 39.5648V31.6685C465.91 31.0868 466.236 30.7604 466.818 30.7604H467.797C468.379 30.7604 468.705 31.0868 468.705 31.6685V39.5648C468.705 40.1466 468.379 40.4729 467.797 40.4729H466.818C466.236 40.4729 465.91 40.1466 465.91 39.5648Z"
                fill="white"
              />
              <path
                d="M472.146 26.6384H473.125C473.707 26.6384 474.033 26.9647 474.033 27.5465V39.5647C474.033 40.1465 473.707 40.4729 473.125 40.4729H472.146C471.564 40.4729 471.238 40.1465 471.238 39.5647V27.5465C471.238 26.9647 471.564 26.6384 472.146 26.6384Z"
                fill="white"
              />
              <path
                d="M483.214 36.5142L479.035 36.9469C479.39 38.0537 480.291 38.451 481.603 38.451C482.249 38.451 483.015 38.3446 483.866 38.1317C484.512 37.9828 484.895 38.2594 484.895 38.9051V39.203C484.895 39.7351 484.647 40.0827 484.122 40.2175C483.051 40.5013 482.043 40.6503 481.114 40.6503C478.12 40.6503 476.112 39.0186 476.112 35.6203C476.112 32.4064 477.815 30.576 481.135 30.576C483.923 30.576 485.576 31.8814 485.576 34.3503C485.576 35.7338 484.995 36.3155 483.214 36.5071M482.98 34.0949C482.98 33.1584 482.405 32.6547 481.142 32.6547C479.766 32.6547 479.035 33.279 478.886 34.9463L482.299 34.5986C482.873 34.5419 482.98 34.4284 482.98 34.0949Z"
                fill="white"
              />
              <path
                d="M486.953 39.5151L488.017 30.1361C488.222 28.1922 488.996 27.4472 490.599 27.4472C492.203 27.4472 492.905 28.1922 493.267 30.1361L494.622 36.954C494.679 37.2591 494.792 37.3584 494.92 37.3584C495.047 37.3584 495.168 37.2591 495.218 36.954L496.58 30.1361C496.942 28.1922 497.63 27.4472 499.24 27.4472C500.851 27.4472 501.624 28.1922 501.83 30.1361L502.901 39.5151C502.972 40.1182 502.653 40.4729 502.043 40.4729H501.092C500.532 40.4729 500.205 40.1891 500.141 39.6145L499.219 30.7604C499.198 30.6185 499.134 30.5547 498.999 30.5547C498.864 30.5547 498.808 30.6256 498.793 30.7533L497.183 38.7915C496.935 39.9976 496.232 40.48 494.92 40.48C493.607 40.48 492.905 39.9976 492.664 38.7915L491.053 30.7533C491.032 30.6256 490.975 30.5547 490.847 30.5547C490.72 30.5547 490.649 30.6256 490.628 30.7604L489.705 39.6145C489.648 40.1891 489.322 40.4729 488.755 40.4729H487.804C487.194 40.4729 486.874 40.1182 486.945 39.5151"
                fill="#FF5100"
              />
              <path
                d="M513.451 34.3787V37.6422C513.451 39.6996 512.514 40.48 510.386 40.48H508.144C505.732 40.48 504.256 39.8344 504.256 37.5144C504.256 35.1945 505.739 34.5418 508.144 34.5418H510.705V34.3857C510.705 33.3003 509.847 32.8249 508.4 32.8249C507.69 32.8249 506.768 32.9455 505.853 33.13C505.228 33.2506 504.852 32.9597 504.852 32.3283V31.9594C504.852 31.4131 505.157 31.0584 505.803 30.9307C506.881 30.7036 507.931 30.583 508.797 30.583C511.479 30.583 513.451 31.7182 513.451 34.3787ZM510.705 37.5003V36.4999H508.286C507.087 36.4999 506.846 36.7766 506.846 37.5074C506.846 38.2381 507.094 38.5077 508.279 38.5077H509.726C510.578 38.5077 510.712 38.1601 510.712 37.5003"
                fill="#FF5100"
              />
              <path
                d="M515.31 39.2314V38.9547C515.31 38.3233 515.686 38.0253 516.324 38.1459C518.63 38.5929 520.993 38.7277 520.993 37.6351C520.993 37.2094 520.687 36.9753 520.021 36.798L517.942 36.2659C516.005 35.7692 515.175 34.8044 515.175 33.4706C515.175 30.6966 518.765 30.1006 522.234 30.9591C522.766 31.0868 523.014 31.4415 523.014 31.9736V32.2078C523.014 32.8534 522.638 33.1301 522 32.9882C520.084 32.5767 517.807 32.4773 517.807 33.3855C517.807 33.7118 518.062 33.9388 518.509 34.0452L520.737 34.6199C522.674 35.1165 523.61 36.0601 523.61 37.5642C523.61 40.6219 519.609 41.1824 516.076 40.253C515.551 40.1111 515.303 39.7706 515.303 39.2314"
                fill="#FF5100"
              />
              <path
                d="M531.308 31.5692V32.1439C531.308 32.7257 530.96 33.052 530.308 33.0662C528.229 33.0946 528.144 33.2719 528.144 34.0169V39.5719C528.144 40.1537 527.817 40.4801 527.236 40.4801H526.257C525.675 40.4801 525.349 40.1537 525.349 39.5719V33.6196C525.349 31.3564 526.157 30.7463 530.343 30.6753C530.975 30.6682 531.315 30.9804 531.315 31.5763"
                fill="#FF5100"
              />
              <path
                d="M457.567 45.9287H457.432C455.176 45.9287 453.338 47.7662 453.338 50.0294V51.5051C453.338 51.8385 453.069 52.1081 452.735 52.1081H451.146V51.576C451.146 47.8655 449.543 45.9855 446.379 45.9855C444.818 45.9855 443.335 46.7517 441.852 48.3196V47.6172C441.852 47.1703 441.49 46.8084 441.043 46.8084H439.83C439.383 46.8084 439.022 47.1703 439.022 47.6172V52.1081H430.16V47.454C430.16 47.0071 429.799 46.6453 429.352 46.6453H428.124C427.677 46.6453 427.315 47.0071 427.315 47.454V54.8111C427.315 55.5348 426.854 55.9959 426.145 55.9959H425.989C425.542 55.9959 425.18 56.3578 425.18 56.8047V58.0179C425.18 58.4649 425.542 58.8267 425.989 58.8267H426.145C428.316 58.8267 430.089 57.0885 430.153 54.9318H452.721C453.58 54.9318 454.374 54.6338 454.97 54.0804C455.765 54.6054 456.687 54.8821 457.659 54.8821C459.865 54.8821 461.653 53.0446 461.653 50.7885V50.0365C461.653 47.7804 459.816 45.9358 457.56 45.9358M456.155 50.0365C456.155 49.2632 456.644 48.7665 457.411 48.7665H457.581C458.34 48.7665 458.837 49.2632 458.837 50.0365V50.7885C458.837 51.4838 458.312 52.0442 457.666 52.0442C457.056 52.0442 456.51 51.8243 456.162 51.4412V50.0365H456.155ZM441.845 52.1152V51.1645C443.2 49.5399 444.534 48.7878 446.038 48.7878C447.542 48.7878 448.401 49.8307 448.401 51.5831V52.1152H441.852H441.845Z"
                fill="#FF5100"
              />
              <path
                d="M502.795 56.152C501.823 56.152 501.27 56.7124 501.27 57.6773C501.27 58.6422 501.83 59.1885 502.795 59.1885C503.76 59.1885 504.32 58.6493 504.32 57.6773C504.32 56.7054 503.76 56.152 502.795 56.152Z"
                fill="white"
              />
              <path
                d="M487.038 56.152C486.066 56.152 485.512 56.7124 485.512 57.6773C485.512 58.6422 486.073 59.1885 487.038 59.1885C488.003 59.1885 488.563 58.6493 488.563 57.6773C488.563 56.7054 488.003 56.152 487.038 56.152Z"
                fill="white"
              />
              <path
                d="M483.888 56.152C482.916 56.152 482.362 56.7124 482.362 57.6773C482.362 58.6422 482.923 59.1885 483.888 59.1885C484.853 59.1885 485.413 58.6493 485.413 57.6773C485.413 56.7054 484.853 56.152 483.888 56.152Z"
                fill="white"
              />
              <path
                d="M488.549 52.4912V47.6314C488.549 47.1915 488.194 46.8368 487.754 46.8368H486.541C486.101 46.8368 485.746 47.1915 485.746 47.6314V51.5547C485.746 52.0016 485.434 52.1152 485.25 52.1152H474.438V44.6587C474.438 44.2189 474.083 43.8641 473.643 43.8641H472.416C471.976 43.8641 471.621 44.2189 471.621 44.6587V53.9172C471.621 55.3858 470.642 56.379 469.181 56.379H469.046C467.584 56.379 466.605 55.3929 466.605 53.9172V51.4128C466.605 50.9729 466.251 50.6182 465.811 50.6182H464.597C464.158 50.6182 463.803 50.9729 463.803 51.4128V53.9172C463.803 56.8189 466.158 59.1743 469.06 59.1743H469.181C471.798 59.1743 473.913 57.429 474.353 54.9104H486.137C487.485 54.9104 488.542 53.8462 488.542 52.4841"
                fill="white"
              />
              <path
                d="M504.015 46.6949H502.788C502.341 46.6949 501.979 47.0567 501.979 47.5036V51.5476C501.979 51.9803 501.688 52.0939 501.511 52.0939H494.132C494.083 52.0939 493.65 52.0796 493.65 51.5476V44.6516C493.65 44.2046 493.288 43.8428 492.841 43.8428H491.614C491.167 43.8428 490.805 44.2046 490.805 44.6516V52.484C490.805 53.8533 491.883 54.9175 493.26 54.9175H502.383C503.753 54.9175 504.817 53.8462 504.817 52.484V47.4965C504.817 47.0496 504.455 46.6878 504.008 46.6878"
                fill="white"
              />
              <path
                d="M527.222 45.9287H527.073C524.816 45.9287 522.979 47.7662 522.979 50.0222V51.4979C522.979 51.8314 522.709 52.101 522.376 52.101H514.529V50.0435C514.529 47.7874 512.692 45.95 510.436 45.95H510.301C507.974 45.95 506.221 47.7094 506.221 50.0435V50.7956C506.221 53.0374 507.988 54.875 510.166 54.8891H511.699V54.9246C511.699 55.6483 511.237 56.1094 510.528 56.1094H510.372C509.925 56.1094 509.563 56.4712 509.563 56.9182V58.1314C509.563 58.5783 509.925 58.9402 510.372 58.9402H510.528C512.741 58.9402 514.543 57.1381 514.543 54.9246V54.8962H519.687V54.9175H522.383C523.241 54.9175 524.036 54.6196 524.632 54.0662C525.427 54.5912 526.349 54.8679 527.321 54.8679C529.527 54.8679 531.315 53.0304 531.315 50.7743V50.001C531.315 47.752 529.485 45.9216 527.236 45.9216M528.491 50.0152V50.7672C528.491 51.4554 527.966 52.0229 527.321 52.0229C526.675 52.0229 526.164 51.803 525.817 51.4199V50.0152C525.817 49.2419 526.306 48.7452 527.073 48.7452H527.243C528.002 48.7452 528.499 49.2419 528.499 50.0152M510.223 52.0584C509.599 52.0584 509.066 51.4766 509.066 50.7885V50.0364C509.066 49.2631 509.556 48.7665 510.322 48.7665H510.492C511.244 48.7665 511.727 49.2631 511.727 50.0364V52.0655H510.223V52.0584Z"
                fill="white"
              />
              <text
                fill="#FF5100"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Noto Kufi Arabic"
                font-size="18"
                font-weight="800"
                letter-spacing="0px"
              >
                <tspan x="210.585" y="141.503">
                  &#x645;&#x628;&#x627;&#x6cc;&#x639;&#x629;
                  &#x62c;&#x6be;&#x627;&#x632;
                  &#x645;&#x648;&#x628;&#x627;&#x6cc;&#x644;
                </tspan>
              </text>
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="14"
                letter-spacing="0em"
              >
                <tspan x="346.852" y="189.843">
                  &#x625;&#x646;&#x647; &#x641;&#x64a; &#x64a;&#x648;&#x645;
                  {{ paymentSession.order_day }}
                  &#x627;&#x644;&#x645;&#x648;&#x627;&#x641;&#x642;
                  {{ (paymentSession.ar_date) }}
                  
                </tspan>
              </text>
              <g clip-path="url(#clip1_0_1)">
                <path
                  d="M36.5 196.5H178.5C181.814 196.5 184.5 199.186 184.5 202.5V239.5C184.5 242.814 181.814 245.5 178.5 245.5H36.5V196.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="86.2461" y="223.865">
                    {{ (e_contract.seller_id_number) }}
                  </tspan>
                </text>
                <path
                  d="M185 206C185 202.962 187.462 200.5 190.5 200.5H249V241.5H190.5C187.462 241.5 185 239.038 185 236V206Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M185 206C185 202.962 187.462 200.5 190.5 200.5H249V241.5H190.5C187.462 241.5 185 239.038 185 236V206Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="197.5" y="223.865">
                    &#x631;&#x642;&#x645; &#x642;&#x648;&#x645;&#x64a;
                  </tspan>
                </text>
              </g>
              <rect
                x="37"
                y="205.5"
                width="212"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <g clip-path="url(#clip2_0_1)">
                <path
                  d="M273.5 196.5H466.5C469.814 196.5 472.5 199.186 472.5 202.5V239.5C472.5 242.814 469.814 245.5 466.5 245.5H273.5V196.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="322.078" y="223.865">  {{ e_contract.seller_name }}</tspan>
                </text>
                <path
                  d="M473 206C473 202.962 475.462 200.5 478.5 200.5H558V241.5H478.5C475.462 241.5 473 239.038 473 236V206Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M473 206C473 202.962 475.462 200.5 478.5 200.5H558V241.5H478.5C475.462 241.5 473 239.038 473 236V206Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="485.5" y="223.865">
                    &#x628;&#x627;&#x639; &#x627;&#x644;&#x637;&#x631;&#x641;
                    &#x627;&#x644;&#x627;&#x648;&#x644;
                  </tspan>
                </text>
              </g>
              <rect
                x="274"
                y="205.5"
                width="284"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <g clip-path="url(#clip3_0_1)">
                <path
                  d="M36.5 244.5H178.5C181.814 244.5 184.5 247.186 184.5 250.5V287.5C184.5 290.814 181.814 293.5 178.5 293.5H36.5V244.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="86.2461" y="271.865">
                    {{ (e_contract.customer_id_number) }}
                  </tspan>
                </text>
                <path
                  d="M185 254C185 250.962 187.462 248.5 190.5 248.5H249V289.5H190.5C187.462 289.5 185 287.038 185 284V254Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M185 254C185 250.962 187.462 248.5 190.5 248.5H249V289.5H190.5C187.462 289.5 185 287.038 185 284V254Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="197.5" y="271.865">
                    &#x631;&#x642;&#x645; &#x642;&#x648;&#x645;&#x64a;
                  </tspan>
                </text>
              </g>
              <rect
                x="37"
                y="253.5"
                width="212"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <g clip-path="url(#clip4_0_1)">
                <path
                  d="M273.5 244.5H430.5C433.814 244.5 436.5 247.186 436.5 250.5V287.5C436.5 290.814 433.814 293.5 430.5 293.5H273.5V244.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="329.082" y="271.865">
                    {{ e_contract.customer_name }}
                  </tspan>
                </text>
                <path
                  d="M437 254C437 250.962 439.462 248.5 442.5 248.5H558V289.5H442.5C439.462 289.5 437 287.038 437 284V254Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M437 254C437 250.962 439.462 248.5 442.5 248.5H558V289.5H442.5C439.462 289.5 437 287.038 437 284V254Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="449.5" y="271.865">
                    &#x627;&#x644;&#x64a; &#x627;&#x644;&#x637;&#x631;&#x641;
                    &#x627;&#x644;&#x62b;&#x627;&#x646;&#x64a;(&#x645;&#x634;&#x62a;&#x631;&#x64a;)
                  </tspan>
                </text>
              </g>
              <rect
                x="274"
                y="253.5"
                width="284"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <line
                x1="36.5"
                y1="308.5"
                x2="557.5"
                y2="308.5"
                stroke="#E9E7E7"
                stroke-dasharray="4 4"
              />
              <g clip-path="url(#clip5_0_1)">
                <path
                  d="M36.5 324.5H339.5C342.814 324.5 345.5 327.186 345.5 330.5V367.5C345.5 370.814 342.814 373.5 339.5 373.5H36.5V324.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre;direction: rtl;"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="320.1992" y="351.865">
                    {{ paymentSession.variants_data.name }}
                  </tspan>
                </text>
                <path
                  d="M346 334C346 330.962 348.462 328.5 351.5 328.5H397V369.5H351.5C348.462 369.5 346 367.038 346 364V334Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M346 334C346 330.962 348.462 328.5 351.5 328.5H397V369.5H351.5C348.462 369.5 346 367.038 346 364V334Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="358.5" y="351.865">
                    &#x645;&#x648;&#x62f;&#x64a;&#x644;
                  </tspan>
                </text>
              </g>
              <rect
                x="37"
                y="333.5"
                width="360"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <g clip-path="url(#clip6_0_1)">
                <path
                  d="M409.5 324.5H477.5C480.814 324.5 483.5 327.186 483.5 330.5V367.5C483.5 370.814 480.814 373.5 477.5 373.5H409.5V324.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre;"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="431.102" y="351.865">
                    {{ paymentSession.variants_data.brand_name }}
                  </tspan>
                </text>
                <path
                  d="M484 334C484 330.962 486.462 328.5 489.5 328.5H557V369.5H489.5C486.462 369.5 484 367.038 484 364V334Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M484 334C484 330.962 486.462 328.5 489.5 328.5H557V369.5H489.5C486.462 369.5 484 367.038 484 364V334Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="496.5" y="351.865">
                    &#x645;&#x627;&#x647;&#x648;
                    &#x645;&#x648;&#x628;&#x627;&#x64a;&#x644;
                  </tspan>
                </text>
              </g>
              <rect
                x="410"
                y="333.5"
                width="147"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <g clip-path="url(#clip7_0_1)">
                <path
                  d="M36.5 368.5H417.5C420.814 368.5 423.5 371.186 423.5 374.5V411.5C423.5 414.814 420.814 417.5 417.5 417.5H36.5V368.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="308.195" y="395.865">
                    {{ (paymentSession.variants_data.imei) }}
                  </tspan>
                </text>
                <path
                  d="M424 378C424 374.962 426.462 372.5 429.5 372.5H557V413.5H429.5C426.462 413.5 424 411.038 424 408V378Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M424 378C424 374.962 426.462 372.5 429.5 372.5H557V413.5H429.5C426.462 413.5 424 411.038 424 408V378Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="427.477" y="395.865">( </tspan>
                  <tspan x="460.223" y="395.865">
                    ) &#x648; &#x6cc;&#x62d;&#x645;&#x644; &#x631;&#x642;&#x645;
                    &#x62a;&#x633;&#x644;&#x633;&#x644;&#x64a;
                  </tspan>
                </text>
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="433.289" y="395.865">IMEI</tspan>
                </text>
              </g>
              <rect
                x="37"
                y="377.5"
                width="520"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <line
                x1="36.5"
                y1="432.5"
                x2="557.5"
                y2="432.5"
                stroke="#E9E7E7"
                stroke-dasharray="4 4"
              />
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="13"
                font-weight="491"
                letter-spacing="0em"
              >
                <tspan x="31.29" y="468.104">
                  &#x64a;&#x642;&#x631; &#x627;&#x644;&#x637;&#x631;&#x641;
                  &#x627;&#x644;&#x623;&#x648;&#x644;
                  (&#x627;&#x644;&#x628;&#x627;&#x626;&#x639;)
                  &#x628;&#x627;&#x646;&#x629;
                  &#x64a;&#x645;&#x62a;&#x644;&#x643; &#x647;&#x630;&#x627;
                  &#x627;&#x644;&#x62c;&#x647;&#x627;&#x632;
                  &#x645;&#x644;&#x643;&#x64a;&#x629;
                  &#x62e;&#x627;&#x644;&#x635;&#x629;
                  &#x648;&#x627;&#x646;&#x647; &#x644;&#x645;
                  &#x64a;&#x635;&#x62f;&#x631; &#x623;&#x64a;
                  &#x623;&#x641;&#x639;&#x627;&#x644;
                  &#x64a;&#x639;&#x627;&#x642;&#x628;
                  &#x639;&#x644;&#x64a;&#x647;&#x627;
                  &#x627;&#x644;&#x642;&#x627;&#x646;&#x648;&#x646; &#x643;
                  &#x645;&#x62d;&#x627;&#x636;&#x631;
                  &#x627;&#x644;&#x633;&#x631;&#x642;&#x629; &#x627;&#x648;
                </tspan>
                <tspan x="85.1133" y="484.104">
                   . &#x627;&#x644;&#x627;&#x62e;&#x62a;&#x644;&#x627;&#x633;
                  &#x627;&#x648; &#x62e;&#x644;&#x627;&#x641;&#x629;
                  &#x648;&#x641;&#x649; &#x62d;&#x627;&#x644;&#x629;
                  &#x638;&#x647;&#x648;&#x631; &#x62e;&#x644;&#x627;&#x641;
                  &#x630;&#x644;&#x643; &#x64a;&#x643;&#x648;&#x646;
                  &#x645;&#x633;&#x626;&#x648;&#x644;
                  &#x645;&#x633;&#x626;&#x648;&#x644;&#x64a;&#x629;
                  &#x642;&#x627;&#x646;&#x648;&#x646;&#x64a;&#x629;
                  &#x648;&#x64a;&#x62a;&#x62d;&#x645;&#x644;
                  &#x627;&#x644;&#x645;&#x633;&#x626;&#x648;&#x644;&#x64a;&#x629;
                  &#x643;&#x627;&#x645;&#x644;&#x629;
                  &#x627;&#x645;&#x627;&#x645;
                  &#x627;&#x644;&#x642;&#x627;&#x646;&#x648;&#x646; 
                </tspan>
              </text>
              <g clip-path="url(#clip8_0_1)">
                <path
                  d="M36.5 492.5H405.5C408.814 492.5 411.5 495.186 411.5 498.5V535.5C411.5 538.814 408.814 541.5 405.5 541.5H36.5V492.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="345.766" y="519.865">
                    {{ (paymentSession.total_paid) }}
                  </tspan>
                </text>
                <path
                  d="M412 502C412 498.963 414.462 496.5 417.5 496.5H555V537.5H417.5C414.462 537.5 412 535.038 412 532V502Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M412 502C412 498.963 414.462 496.5 417.5 496.5H555V537.5H417.5C414.462 537.5 412 535.038 412 532V502Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="424.5" y="519.865">
                    &#x62a;&#x645; &#x647;&#x630;&#x627;
                    &#x627;&#x644;&#x628;&#x64a;&#x639;
                    &#x646;&#x638;&#x64a;&#x631; &#x645;&#x628;&#x644;&#x63a;
                    &#x627;&#x62c;&#x645;&#x627;&#x644;&#x64a;
                  </tspan>
                </text>
              </g>
              <rect
                x="37"
                y="501.5"
                width="518"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <g clip-path="url(#clip9_0_1)">
                <path
                  d="M36.5 536.5H481.5C484.814 536.5 487.5 539.186 487.5 542.5V579.5C487.5 582.814 484.814 585.5 481.5 585.5H36.5V536.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="350.191" y="563.865">
                    {{ (paymentSession.total_paid)  }}  EGP 
                  </tspan>
                </text>
                <path
                  d="M488 546C488 542.963 490.462 540.5 493.5 540.5H558V581.5H493.5C490.462 581.5 488 579.038 488 576V546Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M488 546C488 542.963 490.462 540.5 493.5 540.5H558V581.5H493.5C490.462 581.5 488 579.038 488 576V546Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="500.645" y="563.865">
                    &#x641;&#x642;&#x637; &#x648; &#x642;&#x62f;&#x631;&#x647;
                  </tspan>
                </text>
              </g>
              <rect
                x="37"
                y="545.5"
                width="521"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <g clip-path="url(#clip10_0_1)">
                <path
                  d="M36.5 580.5H286.5C289.814 580.5 292.5 583.186 292.5 586.5V623.5C292.5 626.814 289.814 629.5 286.5 629.5H36.5V580.5Z"
                  fill="white"
                />
                <text
                  fill="#333333"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="608"
                  letter-spacing="0px"
                >
                  <tspan x="190.25" y="607.865">
                    {{ paymentSession.payment_provider_name }}
                  </tspan>
                </text>
                <path
                  d="M293 590C293 586.963 295.462 584.5 298.5 584.5H557V625.5H298.5C295.462 625.5 293 623.038 293 620V590Z"
                  fill="#EFEFEF"
                />
                <path
                  d="M293 590C293 586.963 295.462 584.5 298.5 584.5H557V625.5H298.5C295.462 625.5 293 623.038 293 620V590Z"
                  stroke="#DEDEDE"
                />
                <text
                  fill="black"
                  xml:space="preserve"
                  style="white-space: pre"
                  font-family="Markazi Text"
                  font-size="12"
                  font-weight="bold"
                  letter-spacing="0em"
                >
                  <tspan x="295.512" y="607.865">
                    &#x62f;&#x641;&#x639;&#x647;&#x627;
                    &#x627;&#x644;&#x637;&#x631;&#x641;
                    &#x627;&#x644;&#x62b;&#x627;&#x646;&#x64a;
                    (&#x627;&#x644;&#x645;&#x634;&#x62a;&#x631;&#x64a;)
                    &#x644;&#x644;&#x637;&#x631;&#x641;
                    &#x627;&#x644;&#x627;&#x648;&#x644; (
                    &#x627;&#x644;&#x628;&#x627;&#x626;&#x639; ) &#x639;&#x646;
                    &#x637;&#x631;&#x64a;&#x642;
                  </tspan>
                </text>
              </g>
              <rect
                x="37"
                y="589.5"
                width="520"
                height="31"
                rx="3.5"
                stroke="#ECECEC"
              />
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="13"
                font-weight="491"
                letter-spacing="0em"
              >
                <tspan x="12.8633" y="644.104">
                  &#x648;&#x64a;&#x639;&#x62a;&#x628;&#x631;
                  &#x62a;&#x648;&#x642;&#x64a;&#x639;
                  &#x627;&#x644;&#x637;&#x631;&#x641;
                  &#x627;&#x644;&#x623;&#x648;&#x644;
                  (&#x627;&#x644;&#x628;&#x627;&#x626;&#x639;)
                  &#x628;&#x645;&#x62b;&#x627;&#x628;&#x629;
                  &#x645;&#x62e;&#x627;&#x644;&#x635;&#x629;
                  &#x646;&#x647;&#x627;&#x626;&#x64a;&#x629; &#x639;&#x646;
                  &#x643;&#x627;&#x645;&#x644;
                  &#x627;&#x644;&#x62b;&#x645;&#x646; &#x643;&#x645;&#x627;
                  &#x64a;&#x642;&#x631; &#x627;&#x644;&#x637;&#x631;&#x641;
                  &#x627;&#x644;&#x62b;&#x627;&#x646;&#x64a; (
                  &#x627;&#x644;&#x645;&#x634;&#x62a;&#x631;&#x649; )
                  &#x628;&#x627;&#x646;&#x629; &#x639;&#x627;&#x64a;&#x646;
                  &#x627;&#x644;&#x645;&#x639;&#x627;&#x64a;&#x646;&#x629;
                  &#x627;&#x644;&#x62a;&#x627;&#x645;&#x629;
                </tspan>
                <tspan x="400.401" y="660.104">
                  . &#x627;&#x644;&#x646;&#x627;&#x641;&#x64a;&#x629;
                  &#x644;&#x644;&#x62c;&#x647;&#x627;&#x644;&#x629;
                  &#x648;&#x642;&#x62f; &#x62d;&#x627;&#x632;
                  &#x631;&#x636;&#x627;&#x621;&#x647;
                  &#x627;&#x644;&#x62a;&#x627;&#x645; 
                  
                </tspan>
              </text>
              <line
                x1="36.5"
                y1="688.5"
                x2="557.5"
                y2="688.5"
                stroke="#E9E7E7"
                stroke-dasharray="4 4"
              />
              <rect
                x="307.5"
                y="713"
                width="250.028"
                height="112"
                rx="2.81722"
                fill="#F9F9F9"
              />
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="451.529" y="734.835">
                  &#x627;&#x644;&#x637;&#x631;&#x641;
                  &#x627;&#x644;&#x627;&#x648;&#x644;
                  (&#x627;&#x644;&#x628;&#x627;&#x626;&#x639;)
                </tspan>
              </text>
              <text
                fill="#333333"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="12"
                font-weight="608"
                letter-spacing="0px"
              >
                <tspan x="446.578" y="756.175">
                  {{ e_contract.seller_name }} 

                </tspan>
              </text>
              <line
                x1="324.483"
                y1="771.909"
                x2="540"
                y2="771.909"
                stroke="#EDEDED"
                stroke-width="0.704304"
              />
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="12"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="498" y="790.578">
                  :&#x631;&#x642;&#x645; &#x642;&#x648;&#x645;&#x64a;
                </tspan>
              </text>
              <text
                fill="#333333"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="12"
                font-weight="608"
                letter-spacing="0px"
              >
                <tspan x="448.746" y="810.896">
                  {{ (e_contract.seller_id_number ) }}
                </tspan>
              </text>
              <rect
                x="36.5"
                y="713"
                width="250.028"
                height="112"
                rx="2.81722"
                fill="#F9F9F9"
              />
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="161.949" y="734.835">
                  &#x627;&#x644;&#x637;&#x631;&#x641;
                  &#x627;&#x644;&#x62b;&#x627;&#x646;&#x64a;
                  (&#x627;&#x644;&#x645;&#x634;&#x62a;&#x631;&#x64a;)
                </tspan>
              </text>
              <text
                fill="#333333"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="12"
                font-weight="608"
                letter-spacing="0px"
              >
                <tspan x="174.582" y="756.175">
                  {{ e_contract.customer_name }}
                </tspan>
              </text>
              <line
                x1="53.4833"
                y1="771.909"
                x2="269"
                y2="771.909"
                stroke="#EDEDED"
                stroke-width="0.704304"
              />
              <text
                fill="black"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="12"
                font-weight="bold"
                letter-spacing="0em"
              >
                <tspan x="227" y="790.578">
                  :&#x631;&#x642;&#x645; &#x642;&#x648;&#x645;&#x64a;
                </tspan>
              </text>
              <text
                fill="#333333"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Markazi Text"
                font-size="12"
                font-weight="608"
                letter-spacing="0px"
              >
                <tspan x="177.746" y="810.896">
                  {{ (e_contract.customer_id_number) }}
                </tspan>
              </text>
            </g>
            <defs>
              <clipPath id="clip0_0_1">
                <rect width="595" height="993" fill="white" />
              </clipPath>
              <clipPath id="clip1_0_1">
                <rect
                  x="36.5"
                  y="205"
                  width="213"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip2_0_1">
                <rect
                  x="273.5"
                  y="205"
                  width="285"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip3_0_1">
                <rect
                  x="36.5"
                  y="253"
                  width="213"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip4_0_1">
                <rect
                  x="273.5"
                  y="253"
                  width="285"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip5_0_1">
                <rect
                  x="36.5"
                  y="333"
                  width="361"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip6_0_1">
                <rect
                  x="409.5"
                  y="333"
                  width="148"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip7_0_1">
                <rect
                  x="36.5"
                  y="377"
                  width="521"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip8_0_1">
                <rect
                  x="36.5"
                  y="501"
                  width="519"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip9_0_1">
                <rect
                  x="36.5"
                  y="545"
                  width="522"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip10_0_1">
                <rect
                  x="36.5"
                  y="589"
                  width="521"
                  height="32"
                  rx="4"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>
  
  <script>
import { required } from '@validations'
export default {
  data() {
    return {
      required,
      errors_back: [],
      showDismissibleAlert: false,
      paymentSession: {},
      e_contract: {
        customer_id_number: null,
        seller_id_number: null,
        customer_name: null,
        seller_name: null,
        id_back_image: null,
        id_front_image: null,
        seller_id_back_image: null,
        seller_id_front_image: null,
      },
      seller: null,
      buyer: null,
      customer_name: null,
      customer_id_number: null,
      id_front_image_file: null,
      id_back_image_file: null,
      seller_id_front_image_file: null,
      seller_id_back_image_file: null,
      id_front_image: null,
      id_back_image: null,
      seller_id_front_image: null,
      seller_id_back_image: null,
      vendorData: null,
    }
  },
  components: {},
  created() {
    this.id = this.$route.params.id
    this.authPermissions = this.$store.state.authPermissions
    this.fetchData()
  },
  computed: {},
  watch: {},
  methods: {
    convertDate(englishDate) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      const arabicDate = new Date(
        this.paymentSession.success_date
      ).toLocaleDateString('ar-EG', options)
    
      return arabicDate
    },
    convertNumberToArabic(number) {
      const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']

      if(number){
        const convertedNumber =  number.replace(
            /\d/g,
            (match) => arabicNumbers[parseInt(match)]
          )
  
  
        return convertedNumber

      }
    },
    ChangeIDFrontImage(e) {
      // this.id_front_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        this.paymentSession.e_contract.id_front_image =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        let vm = this
        fileReader.onload = (e) => {
          vm.id_front_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
          // console.log(this.id_front_image)
        }
        fileReader.readAsDataURL(image)
      }
    },
    ChangeIDBackImage(e) {
      // this.id_back_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        this.paymentSession.e_contract.id_back_image =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)

        fileReader.onload = (e) => {
          this.id_back_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
      }
    },
    ChangeSellerIDFrontImage(e) {
 
      // this.id_front_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        this.paymentSession.e_contract.seller_id_front_image =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        let vm = this
        fileReader.onload = (e) => {
          vm.seller_id_front_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
          // console.log(this.id_front_image)
        }
        fileReader.readAsDataURL(image)
      }
      console.log(this.paymentSession.e_contract)
    },
    ChangeSellerIDBackImage(e) {
      // this.id_back_image_file = e
      const image = e.target.files[0]
      if (e != null) {
        this.paymentSession.e_contract.seller_id_back_image =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)

        fileReader.onload = (e) => {
          this.seller_id_back_image = e.target.result
            .split(',')
            .map((item) => item.trim())[1]
        }
      }
    },

    fetchData() {
      axios
        .get(
          'payment-sessions/' +
            this.$route.params.id +
            '/showPaymentSessionData'
        )
        .then((result) => {
          this.paymentSession = result.data.data
          
          this.e_contract =  this.paymentSession.e_contract
          // this.customer_name = this.paymentSession.e_contract.customer_name
          // this.customer_id_number = this.paymentSession.e_contract.customer_id_number
        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const data = {}
          if(this.seller_id_back_image_file){
            data['seller_id_back_image'] = this.seller_id_back_image
          }
          if(this.seller_id_front_image_file){
            
            data['seller_id_front_image'] = this.seller_id_front_image
          }
          if(this.id_front_image_file){
            data['id_front_image'] = this.id_front_image
            
          }
          if(this.id_back_image_file){
            data['id_back_image'] = this.id_back_image

          }

          data['customer_id_number'] = this.e_contract.customer_id_number
          data['seller_id_number'] = this.e_contract.seller_id_number
          data['customer_name'] = this.e_contract.customer_name
          data['seller_name'] = this.e_contract.seller_name
    

          axios
            .put(
              '/e-contracts/' + this.paymentSession.e_contract.id + '/update',
              data
            )
            .then((result) => {
             
              
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }

              console.log(this.errors_back)
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    generateEContract() {
      axios
        .get(
          'e-contracts/' + this.paymentSession.e_contract.id + '/imageGenerate'
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.$router.push('/paymentSessions/show/' + this.$route.params.id)
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
  },
}
</script>
  